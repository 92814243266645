// Code for the main.js file
import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify';
import i18n from './plugins/i18n';
import 'vuetify/dist/vuetify.min.css';
import router from './router';

import './assets/global.css'

Vue.use(Vuetify);

const vuetify = new Vuetify();

new Vue({
  vuetify,
  i18n,
  router,
  render: h => h(App),
}).$mount('#app');

