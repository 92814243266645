<template>
    <v-card @drop.prevent="onDrop($event)" @dragover.prevent="dragover = true" @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false" :class="['custom-file-input', { 'grey lighten-2': dragover }]"
        @click="triggerFileInput">
        <input type="file" :multiple="multiple" ref="fileInput" @change="onFileSelected" style="display: none;" />
        <v-card-text>
            <v-row class="d-flex flex-column" dense align="center" justify="center">
                <v-progress-circular v-if="loading" indeterminate color="grey" size="40"
                    class="mb-5 mt-5"></v-progress-circular>
                <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="40" :style="{ color: '#' + labelColor }">
                    fas fa-upload
                </v-icon>
                <p :style="{ color: '#' + labelColor }">
                    {{ $t('form.fileInputPlaceholder') }}
                </p>
                <p :style="{ color: '#' + labelColor }">
                    {{ $t('form.maxFileSize') }}
                </p>
            </v-row>
            <v-virtual-scroll v-if="uploadedFiles.length > 0" :items="uploadedFiles" height="70" item-height="50">
                <template v-slot:default="{ item }">
                    <v-list-item :key="item.name">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                            <v-btn @click.stop="removeFile(item.name)" icon>
                                <v-icon>fas fa-times</v-icon>
                            </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </v-virtual-scroll>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "FileUpload",
    props: {
        multiple: {
            type: Boolean,
            default: false
        },
        projectId: {
            type: String,
            required: true
        },
        labelColor: {
            type: String,
            default: 'black',
        },
    },
    data() {
        return {
            dragover: false,
            uploadedFiles: [],
            imageUrl: '', // Store the URL of the uploaded image
            loading: false,
        };
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        onFileSelected(e) {
            const files = e.target.files;
            if (!files.length) return;
            if (!this.multiple && files.length > 1) {
                this.$store.dispatch("addNotification", {
                    message: "Only one file can be uploaded at a time.",
                    colour: "error"
                });
                return;
            }
            if (!this.multiple) this.uploadedFiles = []; // Clear existing files if not multiple
            Array.from(files).forEach(file => {
                this.uploadedFiles.push({
                    name: file.name,
                    size: file.size
                });
                this.convertToBase64(file);
            });
            e.target.value = '';
        },
        removeFile(fileName) {
            if (!this.multiple) {
                this.uploadedFiles = [];
                this.$refs.fileInput.value = '';
            } else {
                const index = this.uploadedFiles.findIndex(
                    file => file.name === fileName
                );
                if (index > -1) this.uploadedFiles.splice(index, 1);
                this.$refs.fileInput.value = '';
            }
        },
        onDrop(e) {
            this.dragover = false;
            this.loading = true;
            const files = e.dataTransfer.files;
            if (!files.length) return;
            if (!this.multiple && files.length > 1) {
                this.$store.dispatch("addNotification", {
                    message: "Only one file can be uploaded at a time.",
                    colour: "error"
                });
                return;
            }
            if (!this.multiple) this.uploadedFiles = []; // Clear existing files if not multiple
            Array.from(files).forEach(file => {
                this.uploadedFiles.push({
                    name: file.name,
                    size: file.size
                });
                this.convertToBase64(file);
            });
        },
        // convertToBase64(file) {
        //     const reader = new FileReader();
        //     reader.readAsDataURL(file);
        //     reader.onload = () => {
        //         const base64String = reader.result
        //             .replace("data:", "")
        //             .replace(/^.+,/, "");
        //         this.sendImageAPI(base64String, file.name);
        //     };
        //     reader.onerror = error => {
        //         console.error('Error converting file to base64:', error);
        //     };
        // },
        convertToBase64(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String = reader.result
                    .replace("data:", "")
                    .replace(/^.+,/, "");

                this.checkAndCompressImage(base64String, file.name);
            };
            reader.onerror = error => {
                console.error('Error converting file to base64:', error);
            };
        },

        checkAndCompressImage(base64String, fileName) {
            const fileSizeKB = base64String.length * (3 / 4) / 1024; // Base64 size in KB
            if (fileSizeKB > 500) {
                const image = new Image();
                image.src = 'data:image/jpeg;base64,' + base64String;
                image.onload = () => {
                    this.compressImage(image, fileName);
                };
            } else {
                // Send image without compression if it's already less than 100KB
                this.sendImageAPI(base64String, fileName);
            }
        },

        compressImage(image, fileName, quality = 0.8) {
            const canvas = document.createElement('canvas');
            const maxSize = 500000; // 100 KB in bytes

            // Scale the image to maintain aspect ratio
            const width = image.width;
            const height = image.height;
            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, width, height);

            // Recursively compress the image until it's below 100 KB
            const compressToTargetSize = (quality) => {
                canvas.toBlob((blob) => {
                    if (blob.size > maxSize && quality > 0.1) {
                        compressToTargetSize(quality - 0.05); // Reduce quality
                    } else {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(blob);
                        fileReader.onload = () => {
                            const compressedBase64String = fileReader.result
                                .replace("data:", "")
                                .replace(/^.+,/, "");
                            this.sendImageAPI(compressedBase64String, fileName);
                        };
                    }
                }, 'image/jpeg', quality);
            };

            compressToTargetSize(quality);
        },
        sendImageAPI(base64String, name) {
            const url = 'https://sparkprize.azurewebsites.net/api/v1/forms/img';
            const authToken = 'eb0ce20e-1d0c-4154-acf8-3efec6130aa3';
            const user = "forms";
            const data = JSON.stringify({
                imageName: name,
                user: user,
                authToken: authToken,
                base64String: base64String,
                phone: '38591123456',
                projectId: this.projectId,
            });

            fetch(url, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
                body: data,
            })
                .then(response => response.json())
                .then(responseData => {
                    this.imageUrl = responseData.ImgUrl;
                    this.$emit('imageUploaded', this.imageUrl);
                    this.loading = false;
                })
                .catch(error => {
                    console.error('Error:', error);
                    this.loading = false;
                });
        },
    }
};
</script>

<style scoped>
.custom-file-input {
    border: 2px dashed rgba(0, 0, 0, 0.35);
    /* Mimic the outlined style */
    border-radius: 4px;
    /* Optional: if you want rounded corners */
    /* padding: 8px;  */
}

.custom-file-input:hover {
    border-color: rgba(0, 0, 0, 0.35);
    /* Slightly darker border on hover, similar to Vuetify's behavior */
}
</style>
