import Vue from 'vue';
import Router from 'vue-router';
import MyForm from './components/MyForm.vue';
import HForm from './components/h-form.vue';
import ImageCodeForm from './components/image-code-form.vue';
import CodeForm from './components/code-form.vue';
import DateCodeForm from './components/date-code-form.vue';
Vue.use(Router);

export default new Router({
  mode: 'history', // Koristite 'history' mode za čiste URL-ove bez hash-a
  routes: [
    {
      path: '/form',
      name: 'MyForm',
      component: MyForm,
      props: route => ({ labelColor: route.query.labelColor, projectId: route.query.projectId, lang: route.query.lang })
    },
    {
      path: '/h-form',
      name: 'h-form',
      component: HForm,
      props: route => ({ labelColor: route.query.labelColor, buttonColor: route.query.buttonColor, projectId: route.query.projectId, lang: route.query.lang })
    },
    {
      path: '/image-code-form',
      name: 'image-code-form',
      component: ImageCodeForm,
      props: route => ({ labelColor: route.query.labelColor, buttonColor: route.query.buttonColor, projectId: route.query.projectId, lang: route.query.lang, terms: route.query.terms })
    },
    {
      path: '/code-form',
      name: 'code-form',
      component: CodeForm,
      props: route => ({ labelColor: route.query.labelColor, buttonColor: route.query.buttonColor, projectId: route.query.projectId, lang: route.query.lang, terms: route.query.terms })
    },
    {
      path: '/date-code-form',
      name: 'date-code-form',
      component: DateCodeForm,
      props: route => ({ labelColor: route.query.labelColor, buttonColor: route.query.buttonColor, projectId: route.query.projectId, lang: route.query.lang, terms: route.query.terms })
    },
  ],
});
