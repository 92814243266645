<template>
<v-container class="form-container">
    <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
        <v-row>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.billNumberOnlyNumbers') }}</p>
                <v-text-field v-model="form.billNumber" :rules="[rules.code]" :placeholder="$t('form.billNumberPlaceholderOnlyNumbers')" outlined dense type="number"></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.fullName') }}</p>
                <v-text-field v-model="form.fullName" :rules="[rules.required]" :placeholder="$t('form.fullNamePlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="6">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.streetAddress') }}</p>
                <v-text-field v-model="form.street" :rules="[rules.required]" :placeholder="$t('form.streetAddressPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="6">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.place') }}</p>
                <v-text-field v-model="form.place" :rules="[rules.required]" :placeholder="$t('form.placePlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="6">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.zipNumber') }}</p>
                <v-text-field v-model="form.zipNumber" :rules="[rules.required]" :placeholder="$t('form.zipNumberPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="6">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.dateOfBirth') }}</p>
                <v-text-field v-model="form.dateOfBirth" :rules="[rules.date]" :placeholder="$t('form.dateOfBirthPlaceHolder')" outlined dense></v-text-field>
            </v-col>
            <!-- <v-col class="pt-0" cols="12" md="6">
                    <p :style="{ color: '#' + labelColor }">{{ $t('form.dateOfBirth') }}</p>
                    <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="formattedDate" :rules="[rules.required]"
                                :placeholder="$t('form.dateOfBirth')" prepend-icon="mdi-calendar" readonly
                                v-bind="attrs" v-on="on" outlined dense></v-text-field>
                        </template>
<v-date-picker v-model="form.dateOfBirth" @input="updateFormattedDate"> <v-spacer></v-spacer>
    <v-btn text color="primary" @click="saveDate">OK</v-btn>
    <v-btn text color="primary" @click="cancelDate">Cancel</v-btn></v-date-picker>
</v-menu>
</v-col> -->
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.phoneNumber') }}</p>
                <v-text-field v-model="form.phone" :rules="[rules.required, rules.phoneNumber]" :placeholder="$t('form.phoneNumberPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.email') }}</p>
                <v-text-field v-model="form.email" :rules="[rules.required, rules.email]" :placeholder="$t('form.emailPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p>
                    <input type="checkbox" v-model="form.agreeTermConditions">
                    <span :style="{ color: '#' + labelColor }">
                        {{ " " + $t('form.acceptTermsText') + " " }}
                    </span>
                    <a :href="terms" target="_blank">{{ $t('form.acceptTermsTextLink') }}</a>
                </p>
            </v-col>
        </v-row>
        <v-row v-if="!billNumImageUrlError" justify="center">
            <p class="error-message">{{ $t('billNumImageUrlError') }}</p>
        </v-row>
        <v-row v-if="!agreeTermConditionsError" justify="center">
            <p class="error-message">{{ $t('agreeTermConditionsError') }}</p>
        </v-row>
        <v-row justify="center" class="pt-5">
            <v-btn @click="submitForm" :color="'#' + buttonColor" :loading="loading" :style="{ color: 'white', width: '35%', borderRadius: '15px', fontSize: '18px' }">{{ $t('submit')
                    }}</v-btn>
        </v-row>
    </v-form>
    <success-modal :show.sync="showModal" :model="form" :specificText="specificText" :hasError="hasError" />
</v-container>
</template>

<script>
import SuccessModal from './components/SuccessModal';
export default {
    components: {
        SuccessModal
    },
    props: {
        labelColor: {
            type: String,
            default: 'black',
        },
        buttonColor: {
            type: String,
            default: 'black',
        },
        projectId: {
            type: String,
            default: '',
        },
        lang: {
            type: String,
            default: 'hr',
        },
        terms: {
            type: String,
        },
    },
    data() {
        return {
            valid: true,
            billNumImageUrlError: true,
            agreeTermConditionsError: true,
            form: {
                fullName: '',
                street: '',
                place: '',
                zipNumber: '',
                phone: '',
                email: '',
                billNumber: '',
                fileInput: null,
                imageUrl: '',
                agreeTermConditions: false,
                dateOfBirth: '',
                codeOK: false,
            },
            rules: {
                required: value => !!value || this.$t('required'),
                email: value => /.+@.+\..+/.test(value) || this.$t('invalidEmail'),
                phoneNumber: value => /^385\d+/.test(value) || this.$t('invalidPhoneNumber'),
                code: value => /^\d{10}$/.test(value) || this.$t('invalidCodeName'),
                date: value => {
                    // Split the input by commas and trim spaces from each date
                    const dates = value.split(',').map(date => date.trim());

                    // Regular expression for a single date
                    const dateRegex = /^\s*(0?[1-9]|[12][0-9]|3[01])\s?\.\s?(0?[1-9]|1[0-2])\s?\.\s?(\d{4})/;

                    // Check if all dates are valid
                    const allValid = dates.every(date => dateRegex.test(date));

                    return allValid || this.$t('invalidDate');
                }
            },
            showModal: false,
            tempDate: '',
            specificText: {
                title: null,
                text: null,
            },
            hasError: false,
            loading: false,
        };
    },
    created() {
        this.$i18n.locale = this.lang;
    },
    methods: {
        enter: function (event) {
            console.log('enter', event)
        },
        leave: function (event) {
            console.log('leave', event)
        },
        handleImageUploaded(imageUrl) {
            this.form.imageUrl = imageUrl;
        },
        async checkCode() {
            this.loading = true;
            const url = new URL('https://sparkprize.azurewebsites.net/api/v1/reusable/checkcode');
            const authToken = 'eb0ce20e-1d0c-4154-acf8-3efec6130aa3';
            const user = "reusable";
            const params = {
                projectId: this.projectId,
                code: this.form.billNumber,
                user: user,
                authToken: authToken,
            };

            // Append each query parameter to the URL
            Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    }
                });

                if (!response.ok) {
                    this.form.codeOK = false;
                    // if (this.projectId == '6723a902a6419fadc5be0552') {
                    //     this.specificText.title = this.$t('form.errorCodeModalTitle');
                    //     this.specificText.text = 'Molimo provjerite točnost unosa KODa s Tommy računa!';
                    // } else {
                    //     this.specificText.title = this.$t('form.errorCodeModalTitle');
                    //     this.specificText.text = this.$t('form.errorCodeNotExistModalText');
                    // }
                    // this.hasError = true;
                    // this.loading = false;
                    // this.showModal = true;
                } else {
                    const data = await response.json();
                    if (data == false) {
                        this.form.codeOK = false;
                        // this.specificText.title = this.$t('form.errorCodeModalTitle');
                        // this.specificText.text = this.$t('form.errorCodeTakenModalText');
                        // this.hasError = true;
                        // this.loading = false;
                        // this.showModal = true;
                    } else {
                        this.form.codeOK = true;
                        // this.hasError = false;
                        // this.loading = false;
                        // this.submitForm();
                    }
                }
                this.showModal = true;
                this.loading = false;
                this.saveForm();

            } catch (error) {
                console.error('Error:', error);
            }
        },
        updateCodeTaken() {
            const url = 'https://sparkprize.azurewebsites.net/api/v1/reusable/taken';
            const authToken = 'eb0ce20e-1d0c-4154-acf8-3efec6130aa3';
            const user = "reusable";
            const data = JSON.stringify({
                user: user,
                authToken: authToken,
                projectId: this.projectId,
                code: this.form.billNumber,
            });

            fetch(url, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: data,
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    console.log('Code taken updated');
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },
        saveForm() {
            const url = 'https://sparkprize.azurewebsites.net/api/v1/forms/formentry';
            const authToken = 'eb0ce20e-1d0c-4154-acf8-3efec6130aa3';
            const user = "forms";
            var dateOfBirth = this.formatDateToDDMMYYYY(this.form.dateOfBirth);
            var isYoungerThan18 = this.isYoungerThan18(this.form.dateOfBirth);
            const data = JSON.stringify({
                user: user,
                authToken: authToken,
                fullName: this.form.fullName,
                street: this.form.street,
                place: this.form.place,
                zipNumber: Number(this.form.zipNumber),
                phone: this.form.phone,
                email: this.form.email,
                billNumber: this.form.billNumber,
                imgUrl: this.form.imageUrl,
                projectId: this.projectId,
                dateOfBirth: dateOfBirth,
                codeOK: isYoungerThan18 ? false : this.form.codeOK,
            });
            console.log('data', data);
            fetch(url, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: data,
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    if(this.form.codeOK){
                        this.updateCodeTaken();
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },

        submitForm() {
            if (this.$refs.form.validate()) {
                if (this.form.agreeTermConditions == true) {
                    this.agreeTermConditionsError = true;
                }
                if (this.form.agreeTermConditions == false) {
                    this.agreeTermConditionsError = false;
                } else if (this.form.imageUrl === '' && this.form.billNumber === '') {
                    this.billNumImageUrlError = false;
                } else {
                    this.billNumImageUrlError = true;
                    this.checkCode();
                }
            }
        },
        updateFormattedDate() {
            if (this.form.dateOfBirth) {
                const date = new Date(this.form.dateOfBirth);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                this.formattedDate = `${day}.${month}.${year}`;
            }
        },
        saveDate() {
            this.form.dateOfBirth = this.tempDate;
            this.updateFormattedDate();
            this.menu = false;
        },
        cancelDate() {
            this.tempDate = this.form.dateOfBirth;
            this.menu = false;
        },
        formatDateToDDMMYYYY(date) {
            const match = date.match(/^\s*(0?[1-9]|[12][0-9]|3[01])\s?\.\s?(0?[1-9]|1[0-2])\s?\.\s?(\d{4})/);
            if (!match) return null; // Return null if the date doesn't match the expected format
            let [, day, month, year] = match;
            day = day.padStart(2, '0');
            month = month.padStart(2, '0');

            return `${day}.${month}.${year}`;
        },
        isYoungerThan18(date) {
            const match = date.match(/^\s*(0?[1-9]|[12][0-9]|3[01])\s?\.\s?(0?[1-9]|1[0-2])\s?\.\s?(\d{4})/);

            if (!match) return false; // Return false if the date doesn't match the expected format

            let [, day, month, year] = match;

            // Convert to integers
            day = parseInt(day, 10);
            month = parseInt(month, 10) - 1; // Months are zero-indexed in JS Date
            year = parseInt(year, 10);

            // Create a Date object for the given date
            const birthDate = new Date(year, month, day);

            // Get today's date
            const today = new Date();

            // Calculate the age difference in years
            let age = today.getFullYear() - birthDate.getFullYear();

            // Adjust age if the birthday hasn't occurred yet this year
            const monthDiff = today.getMonth() - birthDate.getMonth();
            const dayDiff = today.getDate() - birthDate.getDate();

            if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
                age--;
            }

            return age < 18;
        }
    },
};
</script>
