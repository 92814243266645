<template>
<v-container class="form-container">
    <v-form ref="form" v-model="valid" @submit.prevent="submitForm">
        <v-row>
            <v-col class="pt-0" cols="12">
                <file-upload :projectId="projectId" @imageUploaded="handleImageUploaded" />
            </v-col>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.fullName') }}</p>
                <v-text-field v-model="form.fullName" :rules="[rules.required]" :placeholder="$t('form.fullNamePlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.streetAddress') }}</p>
                <v-text-field v-model="form.street" :rules="[rules.required]" :placeholder="$t('form.streetAddressPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="6">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.place') }}</p>
                <v-text-field v-model="form.place" :rules="[rules.required]" :placeholder="$t('form.placePlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="6">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.zipNumber') }}</p>
                <v-text-field v-model="form.zipNumber" :rules="[rules.required]" :placeholder="$t('form.zipNumberPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.phoneNumber') }}</p>
                <v-text-field v-model="form.phone" :rules="[rules.required, rules.phoneNumber]" :placeholder="$t('form.phoneNumberPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.email') }}</p>
                <v-text-field v-model="form.email" :rules="[rules.required, rules.email]" :placeholder="$t('form.emailPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p :style="{ color: '#' + labelColor }">{{ $t('form.billNumber') }}</p>
                <v-text-field v-model="form.billNumber" :placeholder="$t('form.billNumberPlaceholder')" outlined dense></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
                <p>
                    <input type="checkbox" v-model="form.agreeTermConditions">
                    <span :style="{ color: '#' + labelColor }">
                        {{ " " + $t('form.acceptTermsText') + " " }}
                    </span>
                    <a :href="terms" target="_blank">{{ $t('form.acceptTermsTextLink') }}</a>
                </p>
            </v-col>
        </v-row>
        <v-row v-if="!billNumImageUrlError" justify="center">
            <p class="error-message">{{ $t('billNumImageUrlError') }}</p>
        </v-row>
        <v-row v-if="!agreeTermConditionsError" justify="center">
            <p class="error-message">{{ $t('agreeTermConditionsError') }}</p>
        </v-row>
        <v-row justify="center" class="pt-5">
            <v-btn @click="submitForm" :color="'#' + buttonColor" :style="{ color: 'white', width: '35%', borderRadius: '15px', fontSize: '18px'}">{{ $t('submit') }}</v-btn>
        </v-row>
    </v-form>
    <success-modal :show.sync="showModal" :model="form" />
</v-container>
</template>

<script>
import SuccessModal from './components/SuccessModal';
import FileUpload from './components/FileUpload';
export default {
    components: {
        SuccessModal,
        FileUpload
    },
    props: {
        labelColor: {
            type: String,
            default: 'black',
        },
        buttonColor: {
            type: String,
            default: 'black',
        },
        projectId: {
            type: String,
            default: '',
        },
        lang: {
            type: String,
            default: 'hr',
        },
        terms: {
            type: String,
        },
    },
    data() {
        return {
            valid: true,
            billNumImageUrlError: true,
            agreeTermConditionsError: true,
            form: {
                fullName: '',
                street: '',
                place: '',
                zipNumber: '',
                phone: '',
                email: '',
                billNumber: '',
                fileInput: null,
                imageUrl: '',
                agreeTermConditions: false,
            },
            rules: {
                required: value => !!value || this.$t('required'),
                email: value => /.+@.+\..+/.test(value) || this.$t('invalidEmail'),
                phoneNumber: value => /^385\d+/.test(value) || this.$t('invalidPhoneNumber'),
            },
            showModal: false,
        };
    },
    created() {
        this.$i18n.locale = this.lang;
    },
    methods: {
        enter: function (event) {
            console.log('enter', event)
        },
        leave: function (event) {
            console.log('leave', event)
        },
        handleImageUploaded(imageUrl) {
            this.form.imageUrl = imageUrl;
        },
        saveForm() {
            const url = 'https://sparkprize.azurewebsites.net/api/v1/forms/formentry';
            const authToken = 'eb0ce20e-1d0c-4154-acf8-3efec6130aa3';
            const user = "forms";
            const data = JSON.stringify({
                user: user,
                authToken: authToken,
                fullName: this.form.fullName,
                street: this.form.street,
                place: this.form.place,
                zipNumber: Number(this.form.zipNumber),
                phone: this.form.phone,
                email: this.form.email,
                billNumber: this.form.billNumber,
                imgUrl: this.form.imageUrl,
                projectId: this.projectId,
            });

            fetch(url, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                        'Content-Type': 'application/json',
                        Accept: 'application/json',
                    },
                    body: data,
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    this.showModal = true;
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        },

        submitForm() {
            if (this.$refs.form.validate()) {
                if (this.form.agreeTermConditions == true) {
                    this.agreeTermConditionsError = true;
                }
                if (this.form.agreeTermConditions == false) {
                    this.agreeTermConditionsError = false;
                } else if (this.form.imageUrl === '' && this.form.billNumber === '') {
                    this.billNumImageUrlError = false;
                } else {
                    this.billNumImageUrlError = true;
                    this.saveForm();
                }
            }
        },
    },
};
</script>
