<template>
    <v-dialog v-model="internalShow" persistent max-width="500px">
        <v-card v-if="!hasError">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn @click="closeModal" icon>
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="title">
                {{ $t('successTitle') }}
            </v-card-text>
            <v-card-text class="full-name">
                {{ model.fullName }}
            </v-card-text>
            <v-card-text class="thak-you">
                {{ $t('successMessage') }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeModal">OK</v-btn>
            </v-card-actions>
        </v-card>
        <v-card v-if="hasError">
            <v-card-title>
                <v-spacer></v-spacer>
                <v-btn @click="closeModalWithoutReload" icon>
                    <v-icon>fas fa-times</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="title">
                {{ specificText.title }}
            </v-card-text>
            <v-card-text class="thak-you">
                {{ specificText.text }}
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="closeModalWithoutReload">OK</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        show: {
            type: Boolean,
            required: true
        },
        model: {
            type: Object,
        },
        specificText: {
            type: Object,
        },
        hasError: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            internalShow: this.show
        };
    },
    watch: {
        show(val) {
            this.internalShow = val;
        },
        internalShow(val) {
            this.$emit('update:show', val);
        }
    },
    methods: {
        closeModal() {
            this.internalShow = false;
            window.location.reload();
        },
        closeModalWithoutReload() {
            this.internalShow = false;
        }
    }
};
</script>

<style scoped>
.title {
    text-align: center;
    font-weight: bold;
    font-size: 28px;
}

.full-name {
    text-align: center;
    font-weight: bold;
    font-size: 20px;
}

.thak-you {
    text-align: center;
    font-size: 18px;
}
</style>
