import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    form: {
      fullName: 'Full Name',
      streetAddress: 'Street Address',
      place: 'Place',
      zipNumber: 'ZIP Number',
      phoneNumber: 'Phone Number',
      email: 'E-mail',
      billNumber: 'Bill Number',
      billNumberOnlyNumbers: 'KOD s fiskalnog računa',
      billNumberCode: 'Broj računa',
      fileInput: 'Image',
      fullNamePlaceholder: 'Enter full name',
      streetAddressPlaceholder: 'Enter street address',
      placePlaceholder: 'Enter place',
      zipNumberPlaceholder: 'e.g., 10000',
      phoneNumberPlaceholder: '385XXXXXXXX',
      emailPlaceholder: 'email@example.com',
      billNumberPlaceholder: '123/4567/890',
      billNumberPlaceholderOnlyNumbers: '1234567890',
      fileInputPlaceholder: 'Drag and drop a file here',
      maxFileSize: 'Maximum file size: 10MB',
      acceptTermsText: 'Prihvaćam da se moji podaci koriste u svrhu kontaktiranja u slučaju osvajanja nagradnog fonda prema',
      acceptTermsTextLink: 'PRAVILNIKU NAGRADNE IGRE',
      dateOfBirth: 'Date of Birth',
      dateOfBirthPlaceHolder: 'In fromat 10.10.2024',
      errorCodeModalTitle: 'Error',
      errorCodeNotExistModalText: 'Code does not exist',
      errorCodeTakenModalText: 'Code is already taken',
    },
    submit: 'Submit',
    required: 'This field is required.',
    invalidEmail: 'Invalid email address.',
    invalidPhoneNumber: 'Phone number must start with 385.',
    successTitle: 'Thank you',
    successMessage: 'you have successfully entered the prize game.',
    billNumImageUrlError: 'You must provide image or bill number',
    agreeTermConditionsError: 'Potrebno prihvatiti uvijete nagradne igre',
    invalidCodeName: 'Invalid code name',
    invalidDate: 'Invalid date format',
  },
  hr: {
    form: {
      fullName: 'Ime i Prezime',
      streetAddress: 'Ulica i kućni broj',
      place: 'Mjesto',
      zipNumber: 'Poštanski Broj',
      phoneNumber: 'Broj mobitela u formatu 385',
      email: 'E-mail',
      billNumber: 'Ako nemaš mogućnost slanja fotografije računa ovdje utipkaj broj računa',
      billNumberOnlyNumbers: 'KOD s fiskalnog računa',
      billNumberCode: 'Broj računa',
      fileInput: 'Slika',
      fullNamePlaceholder: 'Ime Prezime',
      streetAddressPlaceholder: 'ulica i kućni broj',
      placePlaceholder: 'mjesto',
      zipNumberPlaceholder: 'npr. 10000',
      phoneNumberPlaceholder: 'npr: 385911234567 (bez razmaka i crtica, sve spojeno)',
      emailPlaceholder: 'email@primjer.com',
      billNumberPlaceholder: 'Unesite broj fiskalnog računa u formatu: 123/4567/890',
      billNumberPlaceholderOnlyNumbers: 'Unesite KOD (10 karaktera, samo brojevi)',
      fileInputPlaceholder: 'Ovdje možeš povući i ispustiti fotografiju računa',
      maxFileSize: 'Maximum file size: 10MB',
      acceptTermsText: 'Prihvaćam da se moji podaci koriste u svrhu kontaktiranja u slučaju osvajanja nagradnog fonda prema',
      acceptTermsTextLink: 'PRAVILNIKU NAGRADNE IGRE',
      dateOfBirth: 'Datum rođenja',
      dateOfBirthPlaceHolder: 'U formatu 10.10.2024',
      errorCodeModalTitle: 'Greška',
      errorCodeNotExistModalText: 'Kod nije ispravan',
      errorCodeTakenModalText: 'Kod je već iskorišten!',
    },
    submit: 'Pošalji',
    required: 'Ovo polje je obavezno.',
    invalidEmail: 'Neispravna e-mail adresa.',
    invalidPhoneNumber: 'Broj telefona mora početi sa 385.',
    successTitle: 'Zahvaljujemo',
    successMessage: 'uspješno ste se prijavili na nagradnu igru.',
    billNumImageUrlError: 'Potrebno je unijeti sliku ili broj računa',
    agreeTermConditionsError: 'Potrebno prihvatiti uvijete nagradne igre',
    invalidCodeName: 'Minimalno 10 brojeva',
    invalidDate: 'Potrebno unijeti datum u formatu dd.mm.gggg',
  },
  bs: {
    form: {
      fullName: 'Ime i Prezime',
      streetAddress: 'Ulica i kućni broj',
      place: 'Mjesto',
      zipNumber: 'Poštanski Broj',
      phoneNumber: 'Broj mobitela u formatu 385',
      email: 'E-mail',
      billNumber: 'Ako nemaš mogućnost slanja fotografije računa ovdje utipkaj broj računa',
      billNumberOnlyNumbers: 'KOD s fiskalnog računa',
      billNumberCode: 'Broj računa',
      fileInput: 'Slika',
      fullNamePlaceholder: 'Ime Prezime',
      streetAddressPlaceholder: 'ulica i kućni broj',
      placePlaceholder: 'mjesto',
      zipNumberPlaceholder: 'npr. 10000',
      phoneNumberPlaceholder: 'npr: 385911234567 (bez razmaka i crtica, sve spojeno)',
      emailPlaceholder: 'email@primjer.com',
      billNumberPlaceholder: 'Unesite broj fiskalnog računa u formatu: 123/4567/890',
      billNumberPlaceholderOnlyNumbers: 'Unesite KOD (10 karaktera, samo brojevi)',
      fileInputPlaceholder: 'Ovdje možeš povući i ispustiti fotografiju računa',
      maxFileSize: 'Maximum file size: 10MB',
      acceptTermsText: 'Prihvaćam da se moji podaci koriste u svrhu kontaktiranja u slučaju osvajanja nagradnog fonda prema',
      acceptTermsTextLink: 'PRAVILNIKU NAGRADNE IGRE',
      dateOfBirth: 'Datum rođenja',
      dateOfBirthPlaceHolder: 'U formatu 10.10.2024',
      errorCodeModalTitle: 'Greška',
      errorCodeNotExistModalText: 'Kod nije ispravan',
      errorCodeTakenModalText: 'Kod je već iskorišten!',
    },
    submit: 'Pošalji',
    required: 'Ovo polje je obavezno.',
    invalidEmail: 'Nevažeća e-mail adresa.',
    invalidPhoneNumber: 'Broj telefona mora početi sa 385.',
    successTitle: 'Zahvaljujemo',
    successMessage: 'uspješno ste se prijavili na nagradnu igru.',
    billNumImageUrlError: 'Potrebno je unijeti sliku ili broj računa',
    agreeTermConditionsError: 'Potrebno prihvatiti uvijete nagradne igre',
    invalidCodeName: 'Minimalno 10 brojeva',
    invalidDate: 'Potrebno unijeti datum u formatu dd.mm.gggg',
  },
  sr: {
    form: {
      fullName: 'Ime i Prezime',
      streetAddress: 'Ulica i kućni broj',
      place: 'Mesto',
      zipNumber: 'Poštanski Broj',
      phoneNumber: 'Broj mobitela u formatu 385',
      email: 'E-mail',
      billNumber: 'Ako nemaš mogućnost slanja fotografije računa ovdje utipkaj broj računa',
      billNumberOnlyNumbers: 'KOD s fiskalnog računa',
      billNumberCode: 'Broj računa',
      fileInput: 'Slika',
      fullNamePlaceholder: 'Unesite ime i prezime',
      streetAddressPlaceholder: 'Unesite ulicu i kućni broj',
      placePlaceholder: 'Unesite mesto',
      zipNumberPlaceholder: 'npr. 10000',
      phoneNumberPlaceholder: '385XXXXXXXX',
      emailPlaceholder: 'email@primer.com',
      billNumberPlaceholder: '123/4567/890',
      billNumberPlaceholderOnlyNumbers: 'Unesite KOD (10 karaktera, samo brojevi)',
      fileInputPlaceholder: 'Ovde možete povući i ispustiti fotografiju računa',
      maxFileSize: 'Maximum file size: 10MB',
      acceptTermsText: 'Prihvaćam da se moji podaci koriste u svrhu kontaktiranja u slučaju osvajanja nagradnog fonda prema',
      acceptTermsTextLink: 'PRAVILNIKU NAGRADNE IGRE',
      dateOfBirth: 'Datum rođenja',
      dateOfBirthPlaceHolder: 'U formatu 10.10.2024',
      errorCodeModalTitle: 'Greška',
      errorCodeNotExistModalText: 'Kod nije ispravan',
      errorCodeTakenModalText: 'Kod je već iskorišten!',
    },
    submit: 'Pošalji',
    required: 'Ovo polje je obavezno.',
    invalidEmail: 'Neispravna e-mail adresa.',
    invalidPhoneNumber: 'Broj telefona mora početi sa 385.',
    successTitle: 'Zahvaljujemo',
    successMessage: 'uspješno ste se prijavili na nagradnu igru.',
    billNumImageUrlError: 'Potrebno je unijeti sliku ili broj računa',
    agreeTermConditionsError: 'Potrebno prihvatiti uvijete nagradne igre',
    invalidCodeName: 'Minimalno 10 brojeva',
    invalidDate: 'Potrebno unijeti datum u formatu dd.mm.gggg',
  }
};

export default new VueI18n({
  locale: 'hr',
  fallbackLocale: 'hr',
  messages
});
